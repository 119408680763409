import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CoreBaseConfigModel } from "@smarttask-common/src/lib/models/Config/CoreBaseConfigModel";
import { Globals } from "@smarttask-common/src/lib/services/globals";
import * as psl from 'psl';

@Injectable({
    providedIn: 'root',
})
export class ConfigService {

    constructor(
        private http: HttpClient,
        private globals: Globals,
    ) {
        
    }

    /*
        - Default environment variables doesn't work because, when Angular gets deployed on server, there is no way to differentiate within Angular code, which environment it is - staging or production.
        - To solve this, we have to make an API call to get the configuration of the environment.
        - Since we are already making a call, its better to get whitelabel information as well.
    */
    fetchConfiguration = () =>{
        let hostname = document.location.hostname;
        if(hostname.indexOf("localhost") != -1){
            //Do nothing
            return Promise.resolve();
        }

        let api_base_url = hostname.indexOf("-staging") ? "https://api-v2-staging.smarttask.io/" : "https://api-v2.smarttask.io/";
        return this.http.get(`${api_base_url}${this.globals.api_version}/config?hostname=${hostname}`).toPromise().then((data: CoreBaseConfigModel) =>{
            this.globals.auth_base_url = data.auth_base_url;
            this.globals.api_base_url = data.api_base_url;
            this.globals.api2_base_url = data.api2_base_url;
            this.globals.app_base_url = data.app_base_url;
            this.globals.main_base_url = data.main_base_url;
            this.globals.form_base_url = data.form_base_url;

            this.globals.app_name = data.app_name;

            this.globals.whitelabel = data.whitelabel;
            if(data.whitelabel){
                //Get the SLD+TLD from the custom domain, save it as cookie domain
                let sld_tld = psl.get(data.whitelabel.custom_domain);
                this.globals.cookie_domain = sld_tld;
            }
        });
    }
}